/**
 * 登录对话框样式
 */
import * as React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import classname from 'classnames'
import { Icon, Modal, Button, Input, Form, message } from 'antd'
import { connect } from 'react-redux'
import Vertify from '../../compontents/Vertify'
import { get, post } from '../../utils/request'

const FormItem = Form.Item
class ModelLogin extends React.Component<any, any> {
    timeout: any = null
    state = {
        count: 60,
        liked: true,
        visible: false, //登录表单
        vertifyShow: false,
    }

    static getDerivedStateFromProps({ visible }: any, prevState: any) {
        if (prevState.visible !== visible) {
            return {
                visible
            }
        }
        return null

    }

    componentDidMount() {

        const token = localStorage.getItem('Auth')
        const phone_name = localStorage.getItem('phone')

        if (token) {
            this.props.dispatch({ type: 'LOGIN_SUCCESS', payload: { token, phone_name } })
        }

    }

    handleClick = () => {
        // this.vertifySuccess()
        if (!this.state.liked) return
        this.props.form.validateFields(['username'], (errors: any, values: any) => {
            if (!errors) {
                this.setState({
                    vertifyShow: true
                })
            }
        });

    }
    vertifySuccess = () => {
        this.setState({
            vertifyShow: false
        })
        this.getCode(this.props.form.getFieldValue('username'))
        this.countDown()
    }

    //倒计时
    countDown = () => {
        const { count } = this.state
        if (count === 1) {
            this.setState({
                count: 60,
                liked: true,
            })
        } else {
            this.setState({
                count: count - 1,
                liked: false,
            })
            this.timeout = setTimeout(this.countDown, 1000)
        }
    }
    //倒计时
    clearCountDown = () => {
        this.timeout && clearTimeout(this.timeout)
        this.setState({
            count: 60,
            liked: true,
        })
    }

    //获取验证码
    getCode = async (phone_name: String) => {
        const res: any = await post({
            url: '/report/customer/get/smscode',
            data: { phone_name },
        })
        res && console.log(res.data, '验证码')
    }

    //登录
    getLogin = () => {

        this.props.form.validateFields(async (errors: any, values: any) => {
            if (!errors) {
                const { username: phone_name, code: sms_code } = values
                const res: any = await post({
                    url: '/report/customer/login',
                    data: { phone_name, sms_code, },
                })
                if (res && res.code === '200') {
                    localStorage.setItem('Auth', res.data.token)
                    localStorage.setItem('phone', phone_name)
                    localStorage.setItem('role', res.data.role)
                    //存储token 后把状态改为登录
                    this.props.dispatch({ type: 'LOGIN_SUCCESS', payload: { token: res.data.token, phone_name } })
                    this.props.form.resetFields()
                    this.clearCountDown()
                } else {
                    if (res && res.code === '030') {
                        this.props.form.setFields({
                            code: {
                                value: '',
                                errors: [new Error(res.msg)]
                            }
                        })
                    } else if (res && res.code === '028') {
                        this.props.form.setFields({
                            username: {
                                errors: [new Error(res.msg)]
                            }
                        })
                    }
                }
            }
        })
    }

    //退出
    loginOut = async () => {
        const res: any = await get({
            url: '/report/customer/signout'
        })
        console.log(res, 'loginout')
        if (res && res.code == '200') {
            localStorage.removeItem('Auth')
            localStorage.removeItem('phone')
            localStorage.removeItem('role')
            this.props.dispatch({ type: 'LOGIN_OUT' })
        } else {
            res && message.error(res.msg)
        }
    }

    handleCancle = () => {
        this.props.dispatch({ type: 'CLOSE_MODAL' })
        this.setState({ vertifyShow: false })
    }
    handleClose = () => {
        this.setState({ vertifyShow: false })
    }

    getImgInfo = async () => {
        const res: any = await get({
            url: '/report/customer/slider/image',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        if (res && res.code === '200') {
            return res.data
        }
        return {}
    }
    verifyPost = (distance: Number, verID: String) => {
        return get({
            url: '/report/customer/slider/verification',
            params: { moveX: distance, verID }
        })
    }

    public render() {
        const { visible } = this.state

        const { getFieldDecorator } = this.props.form
        const { dispatch, loginStatus, phone_name } = this.props

        return (
            <div
                className={classname(
                    'title', 'active',
                    'login'
                )}
            >

                <span
                    className={classname(
                        'title',
                        'active',
                        'login'
                    )}
                >
                    <span className="line">|</span>
                    <span
                        className={classname('hover')}
                    >
                        <Icon type="user" style={{ margin: '0 10px 0 0' }} />

                        {/* 登录成功？ */}
                        {loginStatus ?
                            <span>{/^1\d{10}$/.test(`${phone_name}`) ?
                                `${`${phone_name}`.slice(0, 3)}****${`${phone_name}`.slice(-4)}` : 'phone_name'}</span> :
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.props.dispatch({ type: 'TOLOGIN' })}
                            >登录</span>}
                        <Icon type="caret-down" className="caret-down" />
                    </span>

                    {/* 下拉菜单的内容 */}
                    {loginStatus ? (
                        <ul className="logindownall">
                            <li className="item">
                                <Link
                                    to='/center'
                                    className="item-item"
                                    style={{ border: 'none' }}
                                >
                                    个人中心
                                </Link>
                            </li>
                            <li className="item">
                                <span
                                    onClick={this.loginOut}
                                    className="item-item"
                                    style={{ border: 'none', cursor: 'pointer' }}
                                >
                                    退出
                                </span>
                            </li>
                        </ul>
                    ) : (
                            <ul className="logindown-list">
                                <li className="item">
                                    <div
                                        className="item-item"
                                        style={{ border: 'none' }}
                                    >
                                        <Link to='/center' >
                                            个人中心
                                    </Link>
                                    </div>
                                </li>

                            </ul>
                        )}
                </span>
                <Modal
                    width={580}
                    centered
                    visible={visible}
                    onCancel={this.handleCancle}
                    maskClosable={false}
                    footer={null}
                >

                    <div className='login-modal-box' >
                        <div className='login-modal-title' >登录</div>

                        <div style={{ position: 'relative', height: '160px', width: '100%' }} >
                            <Form className="login-form">
                                <FormItem>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                required: true,
                                                pattern: /^1[3-9]\d{9}$/,
                                                message: '请输入正确的手机号',
                                            },
                                        ],
                                    })(
                                        <Input
                                            style={{
                                                height: '44px',
                                            }}
                                            prefix={
                                                <Icon
                                                    type="mobile"
                                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                                />
                                            }
                                            placeholder="请输入手机号"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                required: true,
                                                pattern: /^\d{6}$/,
                                                message: '请输入正确的验证码',
                                            },
                                        ],
                                    })(
                                        <Input
                                            className='login-input-2'
                                            style={{
                                                height: '44px',
                                                position: 'relative',
                                                margin: '20px 6px 0 0 '
                                            }}
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{
                                                        color: 'rgba(0,0,0,.25)',
                                                    }}
                                                />
                                            }
                                            placeholder="请输入验证码"
                                        />
                                    )}
                                </FormItem>
                            </Form>
                            <div
                                className='post-code-box'
                                onClick={this.handleClick}
                            >
                                {this.state.liked ? (
                                    '发送短信验证码'
                                ) : (
                                        <span
                                            style={{
                                                color: 'red',
                                                marginTop: '-34%',
                                            }}
                                        >
                                            {this.state.count} 秒后重发
                                    </span>
                                    )}
                            </div>
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translate(-50%,-50px)',
                                background: '#fff',
                                zIndex: 1001
                            }} >
                                <Vertify
                                    width={320}
                                    height={160}
                                    visible={this.state.vertifyShow}
                                    getImgInfo={this.getImgInfo}
                                    verifyPost={this.verifyPost}
                                    onSuccess={this.vertifySuccess}
                                    onFail={() => { }}
                                    onRefresh={() => { }}
                                    onClose={this.handleClose}
                                />
                            </div>
                        </div>


                        <Button
                            style={{
                                width: '286px',
                                height: '46px',
                                margin: '24px 0px 36px 0px',
                                borderRadius: '23px',
                                background:
                                    'linear-gradient(180deg, #00C3FF 0%, #0090FF 100%)',
                                boxShadow:
                                    '0px 3px 14px 0px rgba(8, 107, 183, 0.44)',
                            }}
                            type="primary"
                            key="ok"
                            onClick={this.getLogin}
                        >
                            登录
                        </Button>
                    </div>

                </Modal>
            </div>
        )
    }
}

const WrappedRegistrationForm = Form.create()(ModelLogin)
export default connect((state: any) => {
    return {
        visible: state.modelVisble,
        phone_name: state.phone_name,
        loginStatus: state.loginStatus,
    }
})(WrappedRegistrationForm)
