/**
 * header
 */
import * as React from 'react'
import './style.scss'
import { Link, withRouter } from 'react-router-dom'
import { Icon, Divider, Modal, Button, Input } from 'antd'
import classname from 'classnames'
import ModelLogin from '../../redux/Loginglobal/index'
import { connect } from 'react-redux'

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1176276_jwtu737bbws.js',
})
class HeaderSider extends React.Component<any, any> {
    public inter: any
    constructor(props: any) {
        super(props)
        this.state = {
            active: '',
            class_name: '',
            prompt: false,
            bottom: '',
            header_block: false,
            top: 60,
            menu_block: false,
            visible: false, //控制登陆表单
            loginOK: false, //登录后 做操作
            loginSuccess: false, //登录后 显示用户名
        }
    }

    static getDerivedStateFromProps({ homeHeader }: any, prevState: any) {
        if (prevState.class_name !== homeHeader && document.location.pathname === '/') {
            return {
                class_name: homeHeader
            }
        }
        return null

    }
    componentDidUpdate(preProps: any) {
        if (preProps.location.pathname !== this.props.location.pathname) {

            if (this.props.location.pathname === '/') {
                this.props.dispatch({ type: 'CHANGE_HOME_HEADER', payload: { homeHeader: false } })
            } else {
                this.setState({ class_name: false })
            }
        }
    }

    public componentDidMount() {
        const self = this

        this.inter = setInterval(() => {
            let active = ''
            if (document.location.pathname === '/') {
                this.setState({ header_block: true })
                active = 'home'
            }
            if (document.location.pathname === '/financial') {
                this.setState({ header_block: true })
                active = 'financial'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1000) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/riskanalysis') {
                this.setState({ header_block: true })
                active = 'riskanalysis'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1000) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/knowledge') {
                this.setState({ header_block: true })
                active = 'knowledge'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1000) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/news') {
                this.setState({ header_block: true })
                active = 'news'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1000) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/idp') {
                this.setState({ header_block: true })
                active = 'idp'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1000) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/sample') {
                this.setState({ header_block: true })
                active = 'sample'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1000) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/program') {
                this.setState({ header_block: true })
                active = 'program'
                if (document.documentElement.clientHeight < 900) {
                    this.setState({ prompt: false })
                } else if (window.pageYOffset >= 1800) {
                    this.setState({ prompt: true, bottom: ' 765px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/technology') {
                this.setState({ header_block: true })
                active = 'technology'
                if (window.pageYOffset >= 450) {
                    this.setState({ prompt: true, bottom: ' 170px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/case') {
                this.setState({ header_block: true })
                active = 'case'
                if (window.pageYOffset >= 1100) {
                    this.setState({ prompt: true, bottom: ' 170px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/about') {
                this.setState({ header_block: true })
                active = 'about'
                if (window.pageYOffset >= 2200) {
                    this.setState({ prompt: true, bottom: ' 170px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/join') {
                this.setState({ header_block: true })
                active = 'join'
                if (window.pageYOffset >= 2000) {
                    this.setState({ prompt: true, bottom: ' 170px' })
                } else {
                    this.setState({ prompt: false })
                }
            }
            if (document.location.pathname === '/center') {
                this.setState({ header_block: true })
                active = 'center'
                if (window.pageYOffset >= 450) {
                    this.setState({ prompt: true, bottom: ' 170px' })
                } else {
                    this.setState({ prompt: false })
                }
            }

            if (document.location.pathname === '/empty') {
                this.setState({ header_block: false })
            }
            if (active !== this.state.active) {
                this.setState({ active })
            }
        }, 10)

        window.onscroll = function () {
            // const name = document.location.pathname
            if (window.pageYOffset >= 100) {
                self.setState({
                    class_name: true,
                    top: 74,
                })
            } else {
                self.setState({
                    class_name: false,
                    top: 60,
                })
            }
        }

    }
    handleScroll() {
        window.scrollTo(0, 0)
    }

    public render() {
        const {
            active,
            class_name,
            prompt,
            bottom,
            header_block,
            top,
            menu_block,
        } = this.state

        const home = this.props.location.pathname === '/'
        return (
            <div
                className={classname(
                    'header-page',
                    class_name ? 'show' : '',
                    header_block ? 'header-block' : 'header-none'
                )}
            >
                <div className="logo-outer">
                    <Link to={'/'} >
                        <img className="logo" src={`images/${home ? 'logo2' : 'logo3'}.png`} alt="1" />
                    </Link>
                </div>
                <div className="right">
                    <ul className="list">
                        <li
                            className={classname(
                                'title',
                                active === 'home' ? 'active' : ''
                            )}
                            onClick={() => {
                                this.handleScroll()
                            }}
                        >
                            <Link
                                to={'/'}
                                className={classname(
                                    active === 'home' ? '' : 'hover',
                                    'a'
                                )}
                            >
                                首页
                            </Link>
                        </li>
                        <span
                            className={classname(
                                'title',
                                active === 'financial' ? 'active' : '',
                                active === 'riskanalysis' ? 'active' : '',
                                active === 'knowledge' ? 'active' : '',
                                active === 'news' ? 'active' : '',
                                'financial'
                            )}
                        >
                            <span
                                className={classname(
                                    active === 'financial' ? '' : 'hover',
                                    active === 'riskanalysis' ? '' : 'hover',
                                    active === 'knowledge' ? '' : 'hover',
                                    active === 'news' ? '' : 'hover'
                                )}
                            >
                                企业产品
                                <Icon
                                    type="caret-down"
                                    className="caret-down"
                                />
                            </span>
                            <ul
                                className="down-list"
                                style={{ top: `${top / 100}rem` }}
                            >

                                <li className="item">
                                    <Link to={'/riskanalysis'} className="item-item">
                                        智能信用业务管理系统
                                    </Link>
                                </li>
                                <li className="item">
                                    <Link
                                        to={'/knowledge'}
                                        className="item-item"
                                    >
                                        智能投行业务管理系统
                                    </Link>
                                </li>
                                <li className="item">
                                    <Link
                                        to={'/news'}
                                        className="item-item"
                                    >
                                        智能营销业务管理系统
                                    </Link>
                                </li>
                                <li className="item">
                                    <Link
                                        className="item-item"
                                        to={'/financial'}
                                    >
                                        智能财务分析及舆情系统
                                    </Link>
                                </li>
                                <li className="item">
                                    <Link
                                        className="item-item"
                                        to={'/idp'}
                                    >
                                        智能文档处理系统（IDP）&nbsp;
                                    </Link>
                                </li>
                            </ul>
                        </span>

                        <span
                            className={classname(
                                'title',
                                active === 'program' ? 'active' : '',
                                'a'
                            )}
                        >
                            <Link
                                to={'/program'}
                                className={classname(
                                    active === 'program' ? '' : 'hover',
                                    'a'
                                )}
                            >
                                行业解决方案
                            </Link>
                        </span>
                        <span
                            className={classname(
                                'title',
                                active === 'technology' ? 'active' : '',
                                'a'
                            )}
                        >
                            <Link
                                to={'/technology'}
                                className={classname(
                                    active === 'technology' ? '' : 'hover',
                                    'a'
                                )}
                            >
                                核心技术
                            </Link>
                        </span>
                        <span
                            className={classname(
                                'title',
                                active === 'about' ? 'active' : '',
                                'a'
                            )}
                        >
                            <Link
                                to={'/about'}
                                className={classname(
                                    active === 'about' ? '' : 'hover',
                                    'a'
                                )}
                            >
                                关于我们
                            </Link>
                        </span>
                        <span
                            className={classname(
                                'title',
                                active === 'join' ? 'active' : '',
                                'a'
                            )}
                        >
                            <Link
                                to={'/join'}
                                className={classname(
                                    active === 'join' ? '' : 'hover',
                                    'a'
                                )}
                            >
                                加入我们
                            </Link>
                        </span>

                        {/* 登录组件 */}
                        <ModelLogin />
                        <div className="num-outer">
                            <a href="tel:0755-83982886">
                                <div className={classname('num')}>
                                    <IconFont
                                        type="iconhebingxingzhuang"
                                        className="phone"
                                    />
                                    <p>
                                        服务热线
                                        <br />
                                        0755-83982886
                                    </p>
                                </div>
                            </a>
                        </div>
                    </ul>
                </div>
                <div
                    className="horizontal-line-box"
                    onClick={(e) => {
                        e.stopPropagation()
                        this.setState({
                            menu_block: !menu_block,
                        })
                    }}
                >
                    {menu_block === true ? (
                        <div>
                            <IconFont
                                type="iconhebingxingzhuang1"
                                className="chahao"
                            />
                        </div>
                    ) : (
                        <div>
                            <span className="horizontal-line" />
                            <span className="horizontal-line" />
                            <span className="horizontal-line" />
                        </div>
                    )}
                </div>
                <div
                    className={classname(
                        'phone-down-list',
                        menu_block
                            ? 'phone-down-list-block'
                            : 'phone-down-list-none'
                    )}
                >
                    <ul>
                        <Link
                            to={'/'}
                            onClick={() => {
                                this.setState({
                                    menu_block: false,
                                })
                            }}
                        >
                            <span className={'phone-down-list-title'}>
                                首页
                            </span>
                        </Link>
                        <span className={'phone-down-list-title-list'}>
                            <span className={'phone-down-list-title-home'}>
                                企业产品
                            </span>
                            <Link
                                onClick={() => {
                                    this.setState({
                                        menu_block: false,
                                    })
                                }}
                                to={'/riskanalysis'}
                                className="item-item"
                                style={{ border: 'none' }}
                            >
                                <li className="item">智能信用业务管理系统</li>
                            </Link>
                            <Link
                                to={'/knowledge'}
                                className="item-item"
                                onClick={() => {
                                    this.setState({
                                        menu_block: false,
                                    })
                                }}
                            >
                                <li className="item">智能投行业务管理系统</li>
                            </Link>
                            <Link
                                to={'/news'}
                                className="item-item"
                                onClick={() => {
                                    this.setState({
                                        menu_block: false,
                                    })
                                }}
                            >
                                <li className="item">智能营销业务管理系统</li>
                            </Link>
                            <Link
                                className="item-item"
                                to={'/financial'}
                                onClick={() => {
                                    this.setState({
                                        menu_block: false,
                                    })
                                }}
                            >
                                <li className="item">智能财务分析及舆情系统</li>
                            </Link>
                            <Link
                                className="item-item"
                                to={'/idp'}
                                onClick={() => {
                                    this.setState({
                                        menu_block: false,
                                    })
                                }}
                            >
                                <li className="item">智能文档处理系统（IDP）</li>
                            </Link>
                        </span>
                        <Link
                            to={'/program'}
                            onClick={() => {
                                this.setState({
                                    menu_block: false,
                                })
                            }}
                        >
                            <span className={'phone-down-list-title'}>
                                行业解决方案
                            </span>
                        </Link>
                        <Link
                            to={'/technology'}
                            onClick={() => {
                                this.setState({
                                    menu_block: false,
                                })
                            }}
                        >
                            <span className={'phone-down-list-title'}>
                                核心技术
                            </span>
                        </Link>
                        <Link
                            to={'/about'}
                            onClick={() => {
                                this.setState({
                                    menu_block: false,
                                })
                            }}
                        >
                            <span className={'phone-down-list-title'}>
                                关于我们
                            </span>
                        </Link>
                        <Link
                            to={'/join'}
                            onClick={() => {
                                this.setState({
                                    menu_block: false,
                                })
                            }}
                        >
                            <span className={'phone-down-list-title'}>
                                加入我们
                            </span>
                        </Link>
                        <div className="num-outer">
                            <a href="tel:0755-83982886">
                                <div className={classname('num')}>
                                    <IconFont
                                        type="iconhebingxingzhuang"
                                        className="phone"
                                    />
                                    <p className="phone-num">
                                        服务热线 0755-83982886
                                    </p>
                                </div>
                            </a>
                        </div>
                    </ul>
                </div>
                <div
                    className={classname(
                        prompt ? 'prompt-box-block' : 'prompt-box'
                    )}
                    style={{
                        bottom: `${bottom}`,
                    }}
                >
                    <ul>
                        <li
                            onClick={() => {
                                window.scrollTo(0, 0)
                            }}
                        >
                            <img src="/images/return_top.png" alt="返回顶部" />
                            <p>返回顶部</p>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default connect((state: any) => {
    return {
        homeHeader: state.homeHeader,
    }
})(withRouter(HeaderSider))



