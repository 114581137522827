/**
 * 提供前端页面的路由选择
 * 为不同页面提供异步的页面加载
 * 不同页面的标题也在这里提供
 */

import * as React from 'react'
import './global.scss'
// import { checkLogin } from './utils/user'
import { Spin, Alert } from 'antd'
interface IState {
    c: any
}

/**
 * 一个用于异步加载React Component的类
 */
class AsyncComponent extends React.Component<any, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            c: null,
        }
    }

    public componentDidUpdate() {}

    public componentDidMount() {
        if (this.props.needLogin) {
            this.props.require(this)
        }
    }

    public render() {
        if (this.state.c) {
            const Component = this.state.c
            const otherProps = this.props.otherProps
            return <Component {...otherProps} />
        }
        return (
            <div className='loading'>
                <Spin tip="Loading..."></Spin>
            </div>
        )
    }
}

const b = (requireFunc: any, needLogin: boolean = false) => {
    const b = (props: any) => {
        return (
            <AsyncComponent
                otherProps={props}
                require={requireFunc}
                needLogin={needLogin}
            />
        )
    }
    return b
}

const l = (s: any) => {
    const load = (m: any) => {
        s.setState({ c: m.default })
    }
    return load
}

/**
 * 添加、删除 页面的路由，主要修改这里，其他函数和类都是辅助用的
 */
const Pages: any[] = [
    {
        path: '/',
        component: b((s: any) => import('./pages/home').then(l(s)), true),
    },

    // {
    //     path: '/product',
    //     component: b((s: any) => import('./pages/product').then(l(s)),true),
    // },

    {
        path: '/program',
        component: b((s: any) => import('./pages/program').then(l(s)), true),
    },
    {
        path: '/technology',
        component: b((s: any) => import('./pages/technology').then(l(s)), true),
    },
    // {
    //     path: '/case',
    //     component: b((s: any) => import('./pages/case').then(l(s)), true),
    // },

    {
        path: '/about',
        component: b((s: any) => import('./pages/about').then(l(s)), true),
    },

    {
        path: '/join',
        component: b((s: any) => import('./pages/join').then(l(s)), true),
    },

    {
        path: '/financial',
        component: b((s: any) => import('./pages/financial').then(l(s)), true),
    },
    {
        path: '/news',
        component: b((s: any) => import('./pages/News').then(l(s)), true),
    },
    {
        path: '/riskanalysis',
        component: b((s: any) => import('./pages/RiskAnalysis').then(l(s)), true),
    },
    {
        path: '/knowledge',
        component: b((s: any) => import('./pages/Knowledge').then(l(s)), true),
    },
    {
        path: '/center',
        component: b((s: any) => import('./pages/center').then(l(s)), true),
    },
    {
        path: '/idp',
        component: b((s: any) => import('./pages/idp').then(l(s)), true),
    },
    {
        path: '/sample',
        component: b((s: any) => import('./pages/Sample').then(l(s)), true),
    },
]

export default Pages
