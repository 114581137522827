import axios from 'axios'
import { message } from 'antd'
import store from '../redux/store/store.tsx'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error.response)
    }
)


instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('Auth')
        if (token && config.headers.Authorization !== false) {
            config.headers.Authorization = token
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

export async function post(obj, { selfMessage, finallyFn } = {}) {
    const res = await request({ method: 'POST', ...obj }, { finallyFn })
    if (res && res.status === 200) {
        return res.data
    } else {
        res && res.data.message && message.error(selfMessage || res.data.message)
    }
}

export async function get(obj, { selfMessage, finallyFn } = {}) {
    const res = await request({ method: 'GET', ...obj }, { finallyFn })
    if (res && res.status === 200) {
        return res.data
    } else {
        res && res.data.message && message.error(selfMessage || res.data.message)
    }
}

export const axios_ = instance

export async function request(obj, { finallyFn } = {}) {
    return await instance
        .request(obj)
        .then((res) => {
            if (res.data.code === '055') {
                throw new Error('401')
            } else if (res.data.code === '034') {
                throw new Error('402')
            }
            return res
        })
        .catch((err) => {

            if (err && err.message === '401') {

                message.warning('登录已过期，请重新登录')
                localStorage.removeItem('Auth')
                localStorage.removeItem('phone')
                store.dispatch({ type: 'TOLOGIN' })

            } else if (err && err.message === '402') {
                const { modelVisble } = store.getState()
                if (!modelVisble) {
                    // message.warning('请先登录')
                    store.dispatch({ type: 'TOLOGIN' })
                }
            }
        })
        .finally(() => {
            typeof finallyFn === 'function' && finallyFn()
        })
}


export async function download(comp_id, end_date, quarterly_type, filename = '') {
    if (comp_id && end_date && quarterly_type) {

        message.loading('报告下载中...', 0);

        const res = await post({
            url: '/report/customer/download',
            data: {
                comp_id,
                end_date,
                quarterly_type,
            },
            responseType: 'blob',
        })

        if (res && res.size && res.type === 'application/octet-stream') {
            const blob = new Blob([res], {
                type: 'application/pdf;chartset=UTF-8',
            })
            const downEle = document.createElement('a')
            downEle.href = URL.createObjectURL(blob)
            downEle.target = '_blank'
            downEle.rel = 'noopener'
            downEle.download = filename
            downEle.click()

            message.destroy()

            message.success('报告下载成功');
        } else {
            message.destroy()
            message.error('下载报告失败，请稍后再试');
        }
    }
}




