/**
 * 前端主程序框架
 */
import React, { useState, useEffect } from 'react'

import { Router, Route } from 'react-router-dom'
import { Layout, LocaleProvider } from 'antd'
import history from './history'
import Pages from './pageSwitch'
import HeaderSider from './compontents/HeaderSider'
import FooterSider from './compontents/FooterSider'

import './global.scss'
import store from './redux/store/store'


const { Content } = Layout

function useGetScreen() {

    const onResize = () => {
        const { pc } = store.getState()
        if (document.documentElement.clientWidth > 768 && !pc) {
            store.dispatch({ type: 'SET_CLIENTWIDTH', payload: { pc: true } })
        } else if (document.documentElement.clientWidth < 768 && pc) {
            store.dispatch({ type: 'SET_CLIENTWIDTH', payload: { pc: false } })
        }
    }

    useEffect(() => {
        store.dispatch({
            type: 'SET_CLIENTWIDTH',
            payload: { pc: document.documentElement.clientWidth > 768 }
        })

        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [])
}

const pages = Pages.map(p => (
    <Route exact={true} key={p.path} path={p.path} component={p.component} />
))
const App: React.FC = () => {

    useGetScreen()

    return (
        <Router history={history}>
            <Layout>
                <HeaderSider  />
                <Content style={{ minHeight: 'calc(100vh - 2.34rem)' }}>
                    <div
                        style={{
                            padding: '0px',
                        }}
                    >
                        {pages}
                    </div>
                </Content>
                <FooterSider />
            </Layout>
        </Router>
    )
}



// }

export default App
