/**
 * footer
 */
import * as React from 'react'
import './style.scss'
import classname from 'classnames'
export default class FooterSider extends React.Component<any, any> {
    public inter: any
    constructor(props: any) {
        super(props)
        this.state = {
            footer_none: false,
        }
    }
    componentDidMount = () => {
        this.inter = setInterval(() => {
            let active = ''
            if (
                document.location.pathname === '/' ||
                document.location.pathname === '/empty'
            ) {
                this.setState({
                    footer_none: true,
                })
            } else {
                this.setState({
                    footer_none: false,
                })
            }
        }, 10)
    }

    public render() {
        const { footer_none } = this.state
        return (
            <div>
                <footer
                    className={classname(
                        'footer-box',
                        footer_none ? 'footer-none' : ''
                    )}
                >
                    <div className="content">
                        <div className="left">
                            <img src="/images/footer-logo.png" alt="bg" />
                            <div className="footer-text">
                                <p className="footer-title">
                                    深圳市金证优智科技有限公司
                                </p>
                                <p className="e-mail">
                                    联系邮箱：ylin@kingdomai.com
                                </p>
                            </div>
                        </div>
                        <div className="right">
                            <img
                                className="zixun"
                                src="/images/zixunkefu.png"
                                alt="zixun"
                            />
                            <div className="text font">
                                <p className="phone">咨询客服</p>
                                <p className="num">服务电话</p>
                                <a href="tel:0755-83982886">
                                    <p className="num">0755-83982886</p>
                                </a>
                            </div>
                            {/* <div className="attention">
                                <p className="text">
                                    关<br />注<br />公<br />众<br />号
                                </p>
                                <img
                                    className="erweima"
                                    src="/images/erweima.png"
                                    alt="erweima"
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="fter">
                        <div className="record">
                            ©2020 金证优智
                            <img src="/images/police.png" alt="" />
                            京公网安备 110108202032587
                            <span>网站备案/许可证号 京ICP备19008078</span>
                        </div>
                    </div>
                </footer>

                <div className="phone-footer">
                    <div className="phone-footer-top">
                        <div className="phone-top-text">
                            <p className="phone">咨询客服</p>
                            <p className="num">服务电话 0755-83982886</p>
                            <p className="num">联系邮箱 ylin@kingdomai.com</p>
                        </div>
                        <img src="/images/phone/phone-zixun.png" alt="" />
                    </div>
                    <div className="phone-footer-bottom">
                        {/* <div className="public">
                            <div>
                                <p>公众号</p>
                                <p className="guanzhu">
                                    扫码或搜索公众号“金证优智”关注我们
                                </p>
                            </div>
                            <img src="/images/phone/phone_erweima.png" alt="" />
                        </div> */}
                        <div className="phone-footer-bottom-bottom">
                            <div className="company_name">
                                <img
                                    src="/images//phone/phone_footer_logo.png"
                                    alt=""
                                />
                                <p>深圳市金证优智科技有限公司</p>
                            </div>
                            <span>© 2020 金证优智</span>
                            <span>
                                京公网安备 110108202032587 网站备案/许可证号
                                京ICP备19008078
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
