const initState = {
    modelVisble: false,
    loginStatus: false,
    token: "",
    phone_name: '',
    pc: true,
    homeHeader: false,
    centerUpdate: 1
}
export default (state = initState, actions: any) => {

    const { type, payload } = actions

    switch (type) {
        case 'TOLOGIN': // 打开登录弹框
            return {
                ...state,
                modelVisble: true,
                loginStatus: false,
                token: "",
                phone_name: '',
            }
        case 'CLOSE_MODAL': // 关闭登录弹框
            return {
                ...state,
                modelVisble: false
            }
        case 'LOGIN_SUCCESS': // 登录成功
            return {
                ...state,
                modelVisble: false,
                loginStatus: true,
                centerUpdate: ++state.centerUpdate,
                ...payload
            }
        case 'LOGIN_OUT': // 退出登录
            return {
                ...state,
                loginStatus: false,
                token: "",
                phone_name: ''
            }
        case 'SET_CLIENTWIDTH': // 屏幕 变动 是否为pc端
            return {
                ...state,
                pc: payload.pc,
                modelVisble: !payload.pc ? false : state.modelVisble
            }
        case 'CHANGE_HOME_HEADER': // 首页滑动
            return {
                ...state,
                homeHeader: payload.homeHeader
            }
        default:
            return state
    }
}
