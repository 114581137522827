/**
 * 为React-Router提供history对象
 */

import { createBrowserHistory } from 'history'

const history: any = createBrowserHistory({
    /* pass a configuration object here if needed */
})

export default history
